.swiper {
  width: 80%;
  height: 230px;
}

.swiper-slide {
  display: flex;
  overflow: hidden;
  border-radius: 16px;
}
/* swiper-button-disabled */
.swiper-button-prev,
.swiper-button-next {
  width: 40px !important;
  height: 40px !important;
  border-radius: 50%;
  background: rgba(255, 255, 255, 1);

  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.1),
    0px -4px 4px 0px rgba(0, 0, 0, 0.03);
}

.swiper-button-prev {
  left: 0 !important;
  transform: translateX(-100%);
}
.swiper-button-next {
  left: 100% !important;
}

.swiper-button-prev::after,
.swiper-button-next::after {
  font-size: 24px !important;
  font-weight: 900;
}
