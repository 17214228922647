// @font-face {
//   font-family: 'Roboto';
//   src: url('./Roboto-Regular.ttf') format('truetype');
//   font-weight: 400;
//   font-style: normal;
// }

// @font-face {
//   font-family: 'Roboto';
//   src: url('./Roboto-Bold.ttf') format('truetype');
//   font-weight: 700;
//   font-style: normal;
// }

// @font-face {
//   font-family: 'Roboto';
//   src: url('./Roboto-Thin.ttf') format('truetype');
//   font-weight: 100;
//   font-style: normal;
// }

// @font-face {
//   font-family: 'Roboto';
//   src: url('./Roboto-Light.ttf') format('truetype');
//   font-weight: 300;
//   font-style: normal;
// }

// @font-face {
//   font-family: 'Roboto';
//   src: url('./Roboto-Medium.ttf') format('truetype');
//   font-weight: 500;
//   font-style: normal;
// }

@font-face {
  font-family: 'PaytoneOne';
  src: url('./PaytoneOne-Regular.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
}
